// 个人中心接口
import { get, post } from '@/utils/request'

// 获取用户个人信息
export function queryBaseInfo(params) {
    return get('/operate/yh/install/emp/baseInfo', params);
}

// 修改用户基本信息
export function queryUpdateBaseInfo(params) {
    return post('/operate/yh/install/emp/updateBaseInfo', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 校验密码接口
export function queryPwdVerify(params) {
    return post('/operate/yh/install/emp/password/verify', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 修改密码接口
export function queryUpdatePassword(params) {
    return post('/operate/yh/install/emp/password/update', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

// 手机验证码修改密码接口
export function queryCodeUpdate(params) {
    return post('/operate/yh/install/emp/password/codeUpdate', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}
import { getToken, setToken, removeToken, getItem, setItem, delItem } from '@/utils/auth'
import { login, codelogin, logout } from '@/api/login'
import { queryBaseInfo } from '@/api/user'
import config from './../../config'

/*
  刷新当前页面头像不丢失
*/
function getRefreshUserInfo() {
    // if (getItem('manage-userInfo')) {
    //     const { name, avatar, empId, mobileNo } = getItem('manage-userInfo')
    //     if (getToken()) {
    //         return { name, avatar, empId, mobileNo }
    //     }
    // }
    // return {}
    if (getItem(config.tokenSign + '-userInfo')) {
        const { name, avatar, empId, mobileNo } = getItem(config.tokenSign + '-userInfo')
        if (getToken()) {
            return { name, avatar, empId, mobileNo }
        }
    }
    return {}
}

const user = {
    state: {
        user: '',
        token: getToken(),
        name: getRefreshUserInfo().name,
        avatar: getRefreshUserInfo().avatar,
        empId: getRefreshUserInfo().empId,
        mobileNo: getRefreshUserInfo().mobileNo,
        todoNum: 0, // 消息未读
        clearMsg: false, // 识别是否清空了消息
    },

    mutations: {
        // 登录token
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        // 用户头像
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        // 用户名字
        SET_NAME: (state, name) => {
            state.name = name
        },
        // 用户账号
        SET_MOBILENO: (state, mobileNo) => {
            state.mobileNo = mobileNo
        },
        // 用户id
        SET_EMPID: (state, empId) => {
            state.empId = empId
        },
        // 消息未读
        SET_TODONUM: (state, num) => {
            state.todoNum = num
        },
        // 识别是否清空了消息
        SET_CLEARMSG: (state, clearMsg) => {
            state.clearMsg = clearMsg
        },
    },

    actions: {
        // 用户登录
        LoginByUsername({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                let url = userInfo.loginType === 1 ? login : codelogin // loginType 登录方式 1 账号登录 2 手机验证码登录
                url(userInfo).then(response => {
                    const token = response.data.accessToken
                    setToken(token)
                    commit('SET_TOKEN', token)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                queryBaseInfo().then(res => {
                    // console.log(res.data);
                    commit('SET_AVATAR', res.data.avatar)
                    commit('SET_NAME', res.data.name)
                    commit('SET_MOBILENO', res.data.mobileNo)
                    commit('SET_EMPID', res.data.empId)
                    setItem(config.tokenSign + '-userInfo', { empId: res.data.empId, avatar: res.data.avatar, name: res.data.name, mobileNo: res.data.mobileNo })
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    removeToken()
                    sessionStorage.clear()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        setTodoNum({ commit }, num) {
            commit('SET_TODONUM', num)
        },

        setClearMsg({ commit }, state) {
            commit('SET_CLEARMSG', state)
        }
    }
}

export default user
import { get, post } from '@/utils/request';

// 获取系统主题接口
export function getTheme(params) {
    return get('/operate/yh/install/getTheme', params);
}

// 修改保存系统主题
export function getUpdateTheme(params) {
    return post('/operate/yh/install/updateTheme', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}
import { MessageBox } from 'element-ui';

export function customConfirm(title, obj, type) {

    return new Promise((resolve, reject) => {
        console.log(title, obj, type);
        MessageBox.confirm(title, obj, type, {
            // ...obj,
            // beforeClose(action, instance, done) {
            //     console.log(action, instance, done);
            //     if (action == 'confirm') {
            //         instance.$refs['confirm'].$el.onclick = a()
            //         function a(e) {
            //             e = e || window.event;
            //             if (e.detail != 0) {
            //                 done()
            //             }
            //         }
            //     } else {
            //         done()
            //     }
            // }
            beforeClose(action, instance, done) {
                console.log(action);
                if (action === "confirm") {
                    instance.$refs["confirm"].$el.onclick = (function (e) {
                        console.log('e', e);
                        e = e || window.event;
                        if (e.detail != 0) {
                            done();
                        }
                    })();
                } else {
                    done();
                }
            },
        },).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
        // MessageBox.confirm(confirmOptions);
    })
}
import { getModulesStructure } from '@/api/login'
const data = {
    state: {
        loading: false, // 全局加载方法
        appMain: { // appmain这个外层元素的一些基本数据，用的比较多，所以也存起来
            width: '100%',
            height: '980px'
        },
        role: [], // 权限列表
        routes: [], // 路由信息
        saveTenantId: null, // 企业id
        saveTenantName: null, // 企业名称
        themeDataArr: {}, // 存储主题数据
    },
    getters: {
        // 获取加载状态
        getLoadStatus: state => {
            return state.loading
        },
        getAppMain: state => {
            return state.appMain
        },
        // 获取权限
        getRole: state => {
            return state.role
        },
        // 获取路由
        getRoutes: state => {
            return state.routes
        },
        // 获取企业id
        getSaveTenantId: state => {
            return state.saveTenantId
        },
        // 企业名称
        getSaveTenantName: state => {
            return state.saveTenantName
        },
        // 获取主题数据
        getThemeDataArr: state => {
            return state.themeDataArr
        }
    },
    mutations: {
        setLoading: (state, status) => { state.loading = status }, // 加载状态
        setAppMain: (state, node) => { state.appMain = node }, // 参考base代码过来的，类似于调整 appMain组件 高度
        setRole: (state, role) => { state.role = role }, // 权限
        setRoutes: (state, routes) => { state.routes = routes }, // 路由
        setSaveTenantId: (state, saveTenantId) => { state.saveTenantId = saveTenantId }, // 企业id
        setSaveTenantName: (state, saveTenantName) => { state.saveTenantName = saveTenantName }, // 企业名称
        setThemeDataArr: (state, themeDataArr) => { state.themeDataArr = themeDataArr },  // 主题数据
    },
    actions: {
        // 异步获取加载状态
        setLoadingAsync: async (context, loading) => {
            context.commit('setLoading', loading)
        },
        setAppMainAsync(context, node) {
            context.commit('setAppMain', node)
        },
        // 存储企业id
        setSaveTenantIdAsync(context, saveTenantId) {
            context.commit('setSaveTenantId', saveTenantId);
        },
        setSaveTenantNameAsync(context, saveTenantName) {
            context.commit('setSaveTenantName', saveTenantName);
        },
        // setRoleAsync: async ({ commit }) => { // 获取权限（老的，新的不用了） 老代码的
        //     let res = await sysApi.getPower({ modulePlatform: config.modulePlatform })
        //     if (res.status === 200) {
        //         let data = res.data
        //         commit('setRole', data)
        //     }
        // },
        setRoutesAsync: async ({ commit }) => { // 获取路由，以及格式化路由下的权限
            // 查询员工关联的功能菜单  需要携带token请求
            let res = await getModulesStructure()
            console.log(res, '---------------------------------');
            if (res.resultCode === 0) {
                let data = res.data
                console.log(data);
                let role = []
                data.forEach(item => {
                    role.push(item.moduleCode)
                    if (item.children && item.children.length !== 0) {
                        item.children.forEach(item2 => { // 遍历二级权限
                            role.push(item2.moduleCode)
                            if (item2.children && item2.children.length !== 0) { // 二级权限下的按钮
                                item2.children.forEach(item3 => { // 遍历按钮
                                    role.push(item3.moduleCode) // 将按钮权限码添加到权限组中
                                })
                            }
                            if (item2.tabRole && item2.tabRole.length !== 0) { // 二级权限下的tab
                                item2.tabRole.forEach(item3 => { // 遍历tab
                                    role.push(item3.moduleCode) // tab的权限码也不能放过
                                    // if (item3.role && item3.role.length !== 0) { // tab下的按钮
                                    //     item3.role.forEach(item4 => { // 遍历tab下的按钮
                                    //         role.push(item4.moduleCode) // 将按钮权限添加进权限组中
                                    //     })
                                    // }
                                })
                            }
                        })
                    }
                })
                // console.log(role);
                commit('setRole', role)
                commit('setRoutes', data)
            }
        },
        // 存储主题数据
        setThemeDataArrAsync(context, themeDataArr) {
            context.commit('setThemeDataArr', themeDataArr)
        }

    }
}

export default data
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/iconfont.css' // 字体图标
import '@/css/reset.css' // 全局样式
import axios from 'axios';
import { pageUtilsInstall } from '@/utils/vueCommon'
import qs from 'qs'
import Config from './config'
import VueWechatTitle from 'vue-wechat-title' // 设置标签标题
import { customConfirm } from '@/utils/messageBoxRestrict' // 限制 MessageBox 弹框因为回车导致的问题
// echarts组件引入
import * as echarts from 'echarts/core' // 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import { BarChart, PieChart, LineChart } from 'echarts/charts' // 引入柱状图、饼图，图表后缀都为 Chart
// 引入标题，提示框，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  GraphicComponent,
  ToolboxComponent
} from 'echarts/components'
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
pageUtilsInstall(Vue);
Vue.prototype.qs = qs;
Vue.use(VueWechatTitle)
Vue.prototype.$config = Config
Vue.prototype.$confirm = customConfirm
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  GraphicComponent,
  ToolboxComponent,
  BarChart,
  PieChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);
Vue.prototype.$echarts = echarts

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
